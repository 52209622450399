import { Box, Skeleton, Stack, SxProps } from "@mui/material";

interface IProps {
  sx?: SxProps;
  width?: number;
}

const CommentSkeleton = (props: IProps) => {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center", ...props.sx }}>
      <Skeleton variant="circular" width={36} height={36} />
      <Box>
        <Skeleton width={props.width ? props.width / 2 : 50} height={24} />
        <Skeleton width={props.width ? props.width : 100} height={24} />
      </Box>
    </Stack>
  );
};

export { CommentSkeleton };
