import { Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useSignalR } from "../../hooks";
import { queryKeys, useEditActionItemSection, useEditActionItemSortOrder } from "../../http";
import { IPlan } from "../../models";
import { lexorank } from "../../utilities";
import { SignalRGroup } from "../signalR";
import { Block } from "./Block";
import { CreateBlockModal } from "./CreateBlockModal";
import { PlanProvider } from "./PlanProvider";

interface IProps {
  plan: IPlan;
  isRowView: boolean;
  isPastView: boolean;
  canEdit?: boolean;
  onActionItemSortOrderChanged: (updatedPlan: IPlan) => void;
}

const Blocks = (props: IProps) => {
  const { mutate: editActionItemSortOrder, isLoading: isUpdatingSortOrder } = useEditActionItemSortOrder();
  const { mutate: editActionItemSection, isLoading: isUpdatingSection } = useEditActionItemSection();
  const [isCreateBlockModalVisible, setIsCreateBlockModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const signalR = useSignalR();

  const hasEditPermission = (props.canEdit && (!isUpdatingSection || !isUpdatingSortOrder)) ?? false;

  useEffect(() => {
    const actionItemGuids =
      props.plan.blocks.flatMap((x) => x.sections.flatMap((y) => y.actionItems.map((z) => z.guid))) ?? [];

    signalR.joinGroup(SignalRGroup.Plan(props.plan.guid));
    signalR.joinGroups(actionItemGuids.map((x) => SignalRGroup.ActionItem(x)));

    return () => {
      signalR.leaveGroup(SignalRGroup.Plan(props.plan.guid));
      signalR.leaveGroups(actionItemGuids.map((x) => SignalRGroup.ActionItem(x)));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.plan]);

  useEffect(() => {
    const handleShouldRefetchPlan = (planGuid: string) => {
      queryClient.invalidateQueries(queryKeys.plans.getPlan(planGuid));
    };
    const handleShouldRefetchActionItem = (actionItemGuid: string) => {
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemSummary(actionItemGuid));
    };

    signalR.onShouldRefetchPlan(handleShouldRefetchPlan);
    signalR.onShouldRefetchActionItem(handleShouldRefetchActionItem);
    return () => {
      signalR.offShouldRefetchActionItem(handleShouldRefetchActionItem);
      signalR.offShouldRefetchPlan(handleShouldRefetchPlan);
    };
  }, [queryClient, signalR]);

  function handleDragEnd(result: DropResult) {
    const { draggableId: actionItemGuid, destination, source } = result;

    // User dragged the item outside of the container
    if (!destination) {
      return;
    }

    // User dragged the item back to where it was
    if (destination?.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    let toSection = props.plan.blocks
      .flatMap((block) => block.sections)
      .find((section) => section.guid === destination.droppableId);

    let fromSection = props.plan.blocks
      .flatMap((block) => block.sections)
      .find((section) => section.guid === source.droppableId);

    if (toSection === undefined || fromSection === undefined) {
      return;
    }

    let actionItem = fromSection.actionItems.find((x) => x.guid === actionItemGuid);

    if (actionItem === undefined) {
      return;
    }

    let newSortOrder = "";

    if (fromSection.guid === toSection.guid) {
      const updatedPlan: IPlan = {
        ...props.plan,
        blocks: props.plan.blocks.map((block) => ({
          ...block,
          sections: block.sections.map((section) => {
            if (section.guid === toSection!.guid) {
              return {
                ...section,
                actionItems: section.actionItems
                  .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
                  .map((actionItem) => {
                    if (actionItem.guid === actionItemGuid) {
                      newSortOrder = lexorank.getRank(section.actionItems, source.index, destination.index);
                      return {
                        ...actionItem,
                        sortOrder: newSortOrder,
                      };
                    }
                    return actionItem;
                  }),
              };
            }
            return section;
          }),
        })),
      };

      props.onActionItemSortOrderChanged(updatedPlan);

      editActionItemSortOrder({
        actionItemGuid,
        sectionGuid: toSection.guid,
        sortOrder: newSortOrder,
      });
    } else {
      const updatedPlan: IPlan = {
        ...props.plan,
        blocks: props.plan.blocks.map((block) => ({
          ...block,
          sections: block.sections.map((section) => {
            if (section.guid === fromSection!.guid) {
              return {
                ...section,
                actionItems: section.actionItems.filter((actionItem) => actionItem.guid !== actionItemGuid),
              };
            } else if (section.guid === toSection!.guid) {
              newSortOrder = lexorank.getRankAtIndex(section.actionItems, destination.index);
              return {
                ...section,
                actionItems: [
                  ...section.actionItems,
                  {
                    ...actionItem!,
                    sortOrder: newSortOrder,
                  },
                ],
              };
            } else {
              return section;
            }
          }),
        })),
      };

      props.onActionItemSortOrderChanged(updatedPlan);

      editActionItemSection({
        fromPlanGuid: props.plan.guid,
        guid: result.draggableId,
        fromSectionGuid: source.droppableId,
        toSectionGuid: destination.droppableId,
        sortOrder: newSortOrder,
      });
    }
  }

  return (
    <PlanProvider planGuid={props.plan.guid}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Stack
          direction={{ xs: "column", md: props.isRowView ? "column" : "row" }}
          spacing={{ xs: 0.75, md: 1 }}
          alignItems="flex-start"
        >
          {props.plan.blocks
            .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
            .map((block, _) => (
              <Block
                key={block.guid}
                block={block}
                blocks={props.plan.blocks}
                isRowView={props.isRowView}
                isPastView={props.isPastView}
                canEditPlan={hasEditPermission && !props.isPastView}
                onBlockAddClick={() => setIsCreateBlockModalVisible(true)}
              />
            ))}
        </Stack>
      </DragDropContext>

      <CreateBlockModal
        visible={isCreateBlockModalVisible}
        blocks={props.plan.blocks}
        onAdded={() => setIsCreateBlockModalVisible(false)}
        onCancelled={() => setIsCreateBlockModalVisible(false)}
      />
    </PlanProvider>
  );
};

export { Blocks };
