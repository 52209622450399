import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  KeyboardArrowDownRounded,
  ManageSearchRounded,
} from "@mui/icons-material";
import { Button, Divider, Popover, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetTeamsTree } from "../http";
import { ITreeNode } from "../models";
import { TreeView } from "./TreeView";

const BUTTON_TITLE_PLACEHOLDER = "Select Team";

interface IProps {
  isMeVisible: boolean;
  selectedTeamGuids: string[];
  onMeSelected?: () => void;
  onTeamSelected: (aggregateTeamName: string, selectedTeamGuids: string[]) => void;
  onClearClicked: () => void;
}

const TeamPicker = (props: IProps) => {
  const { data: teams, isLoading } = useGetTeamsTree();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [buttonTitle, setButtonTitle] = useState(BUTTON_TITLE_PLACEHOLDER);
  const [tooltipTitle, setTooltipTitle] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    if (teams != null) {
      const selectedTeams = teams?.filter((x) => props.selectedTeamGuids.includes(x.nodeId));
      const aggregateTeamName = getAggregateTeamName(selectedTeams);

      setButtonAndTooltipTitles(aggregateTeamName, selectedTeams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTeamGuids, teams]);

  function handleTeamSelected(selectedTeams: ITreeNode[]) {
    const aggregateTeamName = getAggregateTeamName(selectedTeams);

    props.onTeamSelected(
      aggregateTeamName,
      selectedTeams.map((x) => x.nodeId)
    );
  }

  function getAggregateTeamName(selectedTeams: ITreeNode[]) {
    let aggregateTeamName = "-";
    let shallowestNode: ITreeNode | undefined = undefined;

    if (selectedTeams.length === 1) {
      aggregateTeamName = selectedTeams[0].label;
    } else if (selectedTeams.length > 1) {
      shallowestNode = selectedTeams.sort((a, b) => a.depth - b.depth || a.label.localeCompare(b.label))[0];

      if (shallowestNode) {
        aggregateTeamName = `${shallowestNode.label}... (${selectedTeams.length} teams)`;
      }
    }

    return aggregateTeamName;
  }

  function setButtonAndTooltipTitles(aggregateTeamName: string, selectedTeams: ITreeNode[]) {
    if (props.isMeVisible && selectedTeams.length === 0) {
      setButtonTitle("Me");
      setTooltipTitle(null);
    } else if (selectedTeams.length === 1) {
      setButtonTitle(selectedTeams[0].label);
      setTooltipTitle(null);
    } else if (selectedTeams.length > 1) {
      setButtonTitle(aggregateTeamName ?? "-");
      setTooltipTitle(
        <>
          <Typography sx={{ fontSize: 13, fontWeight: 700 }}>Selected</Typography>
          {selectedTeams
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((x, i) => (
              <Typography key={i} sx={{ display: "inline-block", fontSize: 13, marginRight: "2px" }}>
                {x.label}
                {i < props.selectedTeamGuids.length - 1 && ", "}
              </Typography>
            ))}
        </>
      );
    } else {
      setButtonTitle(BUTTON_TITLE_PLACEHOLDER);
      setTooltipTitle(null);
    }
  }

  function handleClearClicked() {
    setButtonTitle(BUTTON_TITLE_PLACEHOLDER);
    setTooltipTitle(null);

    props.onClearClicked();
  }

  return (
    <>
      <Tooltip
        title={tooltipTitle}
        placement="bottom-start"
        slotProps={{
          tooltip: {
            sx: {
              maxHeight: "400px",
              overflowY: "auto",
            },
          },
        }}
      >
        <Button
          variant="tertiary"
          startIcon={<ManageSearchRounded />}
          endIcon={<KeyboardArrowDownRounded />}
          onClick={(e) => setMenuAnchor(e.currentTarget)}
        >
          {buttonTitle}
        </Button>
      </Tooltip>

      <Popover
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack spacing={0.5} sx={{ maxHeight: "400px", overflow: "auto", p: 0.5, pt: 1 }}>
          {props.isMeVisible && (
            <>
              <Stack
                direction="row"
                spacing={0.25}
                sx={{ alignItems: "center", "&&": { ml: 1.5 }, cursor: "pointer" }}
                onClick={props.onMeSelected}
              >
                {props.selectedTeamGuids.length === 0 ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}
                <Typography>Me</Typography>
              </Stack>
              <Divider variant="dashed" sx={{ color: "#c9c9c9", "&&": { mx: 0.5 } }} />
            </>
          )}

          {isLoading && (
            <Stack>
              {[...Array(3)].map((_, i) => (
                <Skeleton key={i} width={240} />
              ))}
            </Stack>
          )}

          {!isLoading && teams && (
            <TreeView nodes={teams} selectedNodeIds={props.selectedTeamGuids} onNodeSelected={handleTeamSelected} />
          )}
        </Stack>
        <Stack spacing={0.5} sx={{ mx: 1, mb: 0.5 }}>
          <Divider variant="dashed" sx={{ color: "#c9c9c9", mt: "0 !important", mx: 1 }} />
          <Button variant="tertiary" sx={{ alignSelf: "start" }} onClick={handleClearClicked}>
            Clear
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export { TeamPicker };
