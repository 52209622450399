import { useContext } from "react";
import { SignalRContext } from "../components";
import { SignalRActionItemsContext } from "../components/signalR/actionItems";
import { SignalRChatContext } from "../components/signalR/chat";
import { SignalRCommentsContext } from "../components/signalR/comments/SignalRCommentsContext";
import { SignalRNotificationsContext } from "../components/signalR/notifications/SignalRNotificationsContext";
import { SignalRIssuesContext } from "../components/signalR/issues";
import { SignalRMeetingsContext } from "../components/signalR/meetings";
import { SignalRPlansContext } from "../components/signalR/plans";

const useSignalR = () => {
  const signalRContext = useContext(SignalRContext);
  const signalRActionItemsContext = useContext(SignalRActionItemsContext);
  const signalRChatContext = useContext(SignalRChatContext);
  const signalRCommentsContext = useContext(SignalRCommentsContext);
  const signalRNotificationsContext = useContext(SignalRNotificationsContext);
  const signalRIssuesContext = useContext(SignalRIssuesContext);
  const signalRMeetingsContext = useContext(SignalRMeetingsContext);
  const signalRPlansContext = useContext(SignalRPlansContext);

  return {
    joinGroup: signalRContext.joinGroup,
    joinGroups: signalRContext.joinGroups,
    leaveGroup: signalRContext.leaveGroup,
    leaveGroups: signalRContext.leaveGroups,
    ...signalRActionItemsContext,
    ...signalRChatContext,
    ...signalRCommentsContext,
    ...signalRNotificationsContext,
    ...signalRIssuesContext,
    ...signalRMeetingsContext,
    ...signalRPlansContext,
  };
};

export { useSignalR };
