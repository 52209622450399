import { QueryClient, QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditNotificationDto, FetchError, GetNotificationCountDto, InfiniteNotificationsQueryData } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  userId: string;
  notificationGuid: string;
};

const useMarkNotificationAsReadRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function markNotificationAsReadRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: EditNotificationDto = {
      isRead: true,
    };

    const response = await fetch(`${configuration.apiRootUrl}/notifications/${variables.notificationGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { markNotificationAsReadRequest };
};

const useMarkNotificationAsRead = () => {
  const { markNotificationAsReadRequest } = useMarkNotificationAsReadRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: markNotificationAsReadRequest,
    onMutate: async (variables) => {
      const cachedNotificationCount = await optimisticallyUpdateNotificationCount(
        queryClient,
        queryKeys.users.getNotificationCount(variables.userId)
      );

      const cachedNotificationsData = optimisticallyUpdateNotificationsData(
        queryClient,
        queryKeys.users.getNotifications(variables.userId),
        variables
      );

      return { cachedNotificationCount, cachedNotificationsData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(
        queryKeys.users.getNotificationCount(variables.userId),
        context?.cachedNotificationCount
      );
      queryClient.setQueryData(queryKeys.users.getNotifications(variables.userId), context?.cachedNotificationsData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.users.getNotifications(variables.userId));
      queryClient.invalidateQueries(queryKeys.users.getNotificationCount(variables.userId));
    },
  });
};

async function optimisticallyUpdateNotificationCount(queryClient: QueryClient, queryKey: QueryKey) {
  await queryClient.cancelQueries();
  const cachedData = queryClient.getQueryData<GetNotificationCountDto>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: GetNotificationCountDto = {
      ...cachedData,
      unreadCount: cachedData.unreadCount - 1,
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);

    return cachedData;
  }
}

async function optimisticallyUpdateNotificationsData(
  queryClient: QueryClient,
  queryKey: QueryKey,
  variables: Variables
) {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<InfiniteNotificationsQueryData>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: InfiniteNotificationsQueryData = {
      ...cachedData,
      pages: cachedData.pages.map((page) => ({
        ...page,
        notifications: page.notifications.map((notification) => {
          if (notification.guid === variables.notificationGuid) {
            return {
              ...notification,
              isRead: true,
            };
          }

          return notification;
        }),
      })),
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useMarkNotificationAsRead };
