import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isListNode,
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode, $isHeadingNode, HeadingTagType } from "@lexical/rich-text";
import { $isAtNodeEnd, $setBlocksType } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  CheckBox,
  FormatBoldRounded,
  FormatIndentDecreaseRounded,
  FormatIndentIncreaseRounded,
  FormatItalicRounded,
  FormatListBulletedRounded,
  FormatListNumberedRounded,
  FormatStrikethroughRounded,
  FormatUnderlinedRounded,
  LinkRounded,
  NotesRounded,
} from "@mui/icons-material";
import { Box, Divider, IconButton, MenuItem, Select, SelectChangeEvent, Stack, Tooltip, useTheme } from "@mui/material";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  BaseSelection,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import React, { useCallback, useEffect, useState } from "react";
import { TextEditorLinkModal } from "../TextEditorLinkModal";

function getSelectedNode(selection: RangeSelection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

enum ToolBarOption {
  headings = "headings",
}

interface ToolbarPluginProps {
  options?: {
    [key in ToolBarOption]: boolean;
  };
}

const ToolbarPlugin = (props: ToolbarPluginProps) => {
  const [editor] = useLexicalComposerContext();
  const [blockType, setBlockType] = useState("paragraph");
  const [, setSelectedElementKey] = useState<string | null>(null);
  const [, setLastSelection] = useState<BaseSelection | null>(null);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [linkPopoverAnchorEl, setLinkPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);

  const options = {
    [ToolBarOption.headings]: false,
    ...props.options,
  };

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    setLastSelection(selection);

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);

        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getListType() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }

      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, updateToolbar]);

  function handleLinkClicked(e: React.MouseEvent<HTMLButtonElement>) {
    editor.getEditorState().read(() => {
      if (!isLink) {
        setLinkPopoverAnchorEl(e.currentTarget);
      } else {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      }
    });
  }

  function handleOrderedListClicked() {
    if (blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }

  function handleBulletedListClicked() {
    if (blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }

  function handleCheckListClicked() {
    if (blockType !== "check") {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }

  function handleFormatChanged(e: SelectChangeEvent<string>) {
    switch (e.target.value) {
      case "paragraph": {
        editor.update(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createParagraphNode());
          }
        });
        break;
      }
      case "h1":
      case "h2":
      case "h3": {
        const headingSize: HeadingTagType = e.target.value;
        if (blockType !== headingSize) {
          editor.update(() => {
            const selection = $getSelection();
            $setBlocksType(selection, () => $createHeadingNode(headingSize));
          });
        }
        break;
      }
    }
  }

  return (
    <>
      <Stack
        direction="row"
        sx={{ borderBottom: "1px solid #ccc", p: 0.25, alignItems: "center" }}
        divider={<Divider orientation="vertical" flexItem />}
        spacing={0.5}
      >
        {options[ToolBarOption.headings] && (
          <Box>
            <Select value={blockType} onChange={handleFormatChanged} startAdornment={<NotesRounded />}>
              <MenuItem value="paragraph">Normal</MenuItem>
              <MenuItem value="h1">Heading 1</MenuItem>
              <MenuItem value="h2">Heading 2</MenuItem>
              <MenuItem value="h3">Heading 3</MenuItem>
            </Select>
          </Box>
        )}

        <Box>
          <ToolbarButton
            title="Bold"
            icon={<FormatBoldRounded />}
            isActive={isBold}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
          />
          <ToolbarButton
            title="Italic"
            icon={<FormatItalicRounded />}
            isActive={isItalic}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
          />
          <ToolbarButton
            title="Underline"
            icon={<FormatUnderlinedRounded />}
            isActive={isUnderline}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}
          />
          <ToolbarButton
            title="Strikethrough"
            icon={<FormatStrikethroughRounded />}
            isActive={isStrikethrough}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")}
          />
          <ToolbarButton title="Link" icon={<LinkRounded />} isActive={isLink} onClick={handleLinkClicked} />
        </Box>
        <Box>
          <ToolbarButton
            title="Ordered List"
            icon={<FormatListNumberedRounded />}
            isActive={blockType === "number"}
            onClick={handleOrderedListClicked}
          />
          <ToolbarButton
            title="Bulleted List"
            icon={<FormatListBulletedRounded />}
            isActive={blockType === "bullet"}
            onClick={handleBulletedListClicked}
          />
          <ToolbarButton
            title="Check List"
            icon={<CheckBox />}
            isActive={blockType === "check"}
            onClick={handleCheckListClicked}
          />
        </Box>
        <Box>
          <ToolbarButton
            title="Outdent"
            icon={<FormatIndentDecreaseRounded />}
            isActive={false}
            onClick={() => editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)}
          />
          <ToolbarButton
            title="Indent"
            icon={<FormatIndentIncreaseRounded />}
            isActive={false}
            onClick={() => editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)}
          />
        </Box>
      </Stack>
      <TextEditorLinkModal
        anchorEl={linkPopoverAnchorEl}
        open={Boolean(linkPopoverAnchorEl)}
        onClose={() => setLinkPopoverAnchorEl(null)}
        editor={editor}
      />
    </>
  );
};

interface ToolbarButtonProps {
  title: string;
  icon: React.ReactNode;
  isActive: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ToolbarButton = (props: ToolbarButtonProps) => {
  const theme = useTheme();

  return (
    <Tooltip title={props.title} arrow placement="top">
      <IconButton
        size="small"
        onMouseDown={(e) => e.preventDefault()}
        onClick={props.onClick}
        sx={{ color: props.isActive ? theme.palette.turquoise.main : undefined, borderRadius: "10px" }}
      >
        {props.icon}
      </IconButton>
    </Tooltip>
  );
};

export { getSelectedNode, ToolbarPlugin };
export type { ToolBarOption };
