import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { DEFAULT_DRAWER_WIDTH } from "../constants";
import {
  FeatureFlag,
  IDrawerSettings,
  IEditOpspSettings,
  IHomeSettings,
  ISearchSettings,
  ISettingsData,
  ISideMenuSettings,
  ISingleGridSettings,
  ITeamSettings,
  IViewPlanSettings,
  useLocalStorage,
} from "../hooks";

interface ISettingsContext extends ISettingsData {
  updateHomeSettings: (settings: IHomeSettings) => void;
  updateSideMenuSettings: (settings: ISideMenuSettings) => void;
  updateDrawerSettings: (settings: IDrawerSettings) => void;
  updateTeamSettings: (settings: ITeamSettings) => void;
  updateViewPlanSettings: (planGuid: string, settings: IViewPlanSettings) => void;
  updateEditOpspSettings: (settings: IEditOpspSettings) => void;
  updateSearchSettings: (settings: ISearchSettings) => void;
  updateFeatureFlag: (featureFlag: FeatureFlag, value: boolean) => void;
  updateGridSettings: (gridId: string, settings: ISingleGridSettings) => void;
}

const defaultSettingsData: ISettingsData = {
  homeSettings: {
    showPeople: false,
    selectedTeamGuids: [],
  },
  sideMenuSettings: {
    isExpanded: false,
    expandedWidth: 240,
  },
  drawerSettings: {
    width: DEFAULT_DRAWER_WIDTH,
  },
  teamSettings: {
    networkListView: false,
  },
  viewPlanSettings: {},
  editOpspSettings: {
    showHints: true,
  },
  searchSettings: {
    searchHistory: [],
  },
  featureFlags: {
    surveys: false,
    meetings: false,
    grids: false,
  },
  gridSettings: {},
};

const SettingsContext = React.createContext<ISettingsContext>({
  ...defaultSettingsData,
  updateHomeSettings: () => null,
  updateSideMenuSettings: () => null,
  updateDrawerSettings: () => null,
  updateTeamSettings: () => null,
  updateViewPlanSettings: () => null,
  updateEditOpspSettings: () => null,
  updateSearchSettings: () => null,
  updateFeatureFlag: () => null,
  updateGridSettings: () => null,
});

interface IProps {
  children?: React.ReactNode;
}

const SettingsProvider = (props: IProps) => {
  const { getLocalStorageSettings, setLocalStorageSettings } = useLocalStorage();
  const [settings, setSettings] = useState<ISettingsData>(defaultSettingsData);

  useEffect(() => {
    const localStorageSettings = getLocalStorageSettings();

    if (localStorageSettings != null) {
      setSettings(_.merge(settings, localStorageSettings));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLocalStorageSettings(settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  function updateTeamSettings(teamSettings: ITeamSettings) {
    setSettings({
      ...settings,
      teamSettings: teamSettings,
    });
  }

  function updateHomeSettings(homeSettings: IHomeSettings) {
    setSettings({
      ...settings,
      homeSettings: homeSettings,
    });
  }

  function updateSideMenuSettings(sideMenuSettings: ISideMenuSettings) {
    setSettings({
      ...settings,
      sideMenuSettings: sideMenuSettings,
    });
  }

  function updateDrawerSettings(drawerSettings: IDrawerSettings) {
    setSettings({
      ...settings,
      drawerSettings: drawerSettings,
    });
  }

  function updateViewPlanSettings(planGuid: string, viewSettings: IViewPlanSettings) {
    setSettings({
      ...settings,
      viewPlanSettings: {
        ...settings.viewPlanSettings,
        [planGuid]: viewSettings,
      },
    });
  }

  function updateEditOpspSettings(editSettings: IEditOpspSettings) {
    setSettings({
      ...settings,
      editOpspSettings: editSettings,
    });
  }

  function updateSearchSettings(searchSettings: ISearchSettings) {
    setSettings({
      ...settings,
      searchSettings: searchSettings,
    });
  }

  function updateGridSettings(gridId: string, gridSettings: ISingleGridSettings) {
    setSettings({
      ...settings,
      gridSettings: { ...settings.gridSettings, [gridId]: gridSettings },
    });
  }

  const updateFeatureFlag = useCallback(
    (featureFlag: FeatureFlag, value: boolean) => {
      setSettings({
        ...settings,
        featureFlags: {
          ...settings.featureFlags,
          [featureFlag]: value,
        },
      });

      return `Feature flag "${featureFlag}" set to "${value}"`;
    },
    [settings]
  );

  useEffect(() => {
    // @ts-ignore
    window.toggleMeetings = () => updateFeatureFlag("meetings", !settings.featureFlags.meetings);

    // @ts-ignore
    window.toggleSurveys = () => updateFeatureFlag("surveys", !settings.featureFlags.surveys);

    // @ts-ignore
    window.toggleGrids = () => updateFeatureFlag("grids", !settings.featureFlags.grids);
  }, [settings.featureFlags.grids, settings.featureFlags.meetings, settings.featureFlags.surveys, updateFeatureFlag]);

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        updateHomeSettings,
        updateSideMenuSettings,
        updateDrawerSettings,
        updateTeamSettings,
        updateViewPlanSettings,
        updateEditOpspSettings,
        updateSearchSettings,
        updateFeatureFlag,
        updateGridSettings,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export { SettingsContext, SettingsProvider };
