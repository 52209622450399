import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";
import { GetNotificationsDto } from "../../types";

const useGetNotificationsRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getNotificationsRequest({
    pageParam,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.users.getNotifications>>): Promise<GetNotificationsDto> {
    const accessToken = await getAccessToken();

    const url = new URL(`${configuration.apiRootUrl}/notifications`);

    if (pageParam) {
      url.searchParams.set("nextDateTimeUtcCursor", pageParam);
    }

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getNotificationsRequest };
};

const useGetNotifications = (userId: string, enabled: boolean = true) => {
  const { getNotificationsRequest } = useGetNotificationsRequest();

  return useInfiniteQuery({
    queryKey: queryKeys.users.getNotifications(userId),
    queryFn: getNotificationsRequest,
    getNextPageParam: (lastPage, _pages) => lastPage.nextDateTimeUtcCursor,
    enabled: enabled,
  });
};

export { useGetNotifications };
