import { ChromeReaderModeOutlined, MoreVertRounded } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Avatar } from ".";
import { InAppNotificationType, Links } from "../constants";
import { useSession } from "../hooks";
import {
  NotificationDto,
  useDeleteNotification,
  useMarkNotificationAsRead,
  useMarkNotificationAsUnread,
} from "../http";

interface IProps {
  notification: NotificationDto;
  isPageView?: boolean;
  onClick?: () => void;
  onDrawerOpenClicked?: (notification: NotificationDto) => void;
}

export const Notification = (props: IProps) => {
  const navigate = useNavigate();
  const { userId } = useSession();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const { mutate: markNotificationAsRead } = useMarkNotificationAsRead();
  const { mutate: markNotificationAsUnread } = useMarkNotificationAsUnread();
  const { mutate: deleteNotification } = useDeleteNotification(userId);
  const theme = useTheme();

  function getLink(notification: NotificationDto) {
    switch (notification.type) {
      case InAppNotificationType.ActionItemComment:
      case InAppNotificationType.ActionItemStatusChanged:
        return Links.ActionItemDetail(notification.subjectId);
      case InAppNotificationType.RoleEntityComment:
        return Links.RoleDetail(notification.subjectId);
      case InAppNotificationType.UserEntityComment:
        return Links.User(notification.subjectId);
      case InAppNotificationType.TeamEntityComment:
        return Links.Team(notification.subjectId);
      case InAppNotificationType.MetricEntityComment:
        return Links.MetricDetail(notification.subjectId);
    }
  }

  const handleNotificationClick = async (notification: NotificationDto) => {
    markNotificationAsRead({ userId: userId, notificationGuid: notification.guid });
    props.onClick?.();
    navigate(getLink(notification));
  };

  async function toggleReadState(e: React.MouseEvent, notification: NotificationDto) {
    setMenuAnchor(null);
    e.stopPropagation();

    if (notification.isRead) {
      markNotificationAsUnread({ userId: userId, notificationGuid: notification.guid });
    } else {
      markNotificationAsRead({ userId: userId, notificationGuid: notification.guid });
    }
  }

  async function handleDeleteClicked(e: React.MouseEvent, notification: NotificationDto) {
    setMenuAnchor(null);
    e.stopPropagation();

    deleteNotification({ notificationGuid: notification.guid });
  }

  const openDrawerButtonVisible =
    (props.notification.type === InAppNotificationType.ActionItemComment ||
      props.notification.type === InAppNotificationType.ActionItemStatusChanged) &&
    props.onDrawerOpenClicked;

  return (
    <Stack
      onClick={() => handleNotificationClick(props.notification)}
      key={props.notification.guid}
      spacing={1}
      direction="row"
      sx={{
        px: 1,
        py: 0.5,
        alignItems: "center",
        backgroundColor: props.notification.isRead ? theme.palette.common.white : theme.palette.primary.opacity5,
        cursor: "pointer",
      }}
    >
      <Link href={Links.User(props.notification.createdByUser.userId)} underline="none">
        <Avatar user={props.notification.createdByUser} border />
      </Link>
      <Stack spacing={0.25} sx={{ width: "100%" }}>
        <Typography variant="caption">
          {DateTime.fromISO(props.notification.createdDateTimeUtc, { zone: "utc" })
            .toLocal()
            .toLocaleString(DateTime.DATETIME_FULL)}
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: props.notification.body }} />
      </Stack>
      {props.isPageView && (
        <Stack direction="row" spacing={1} onClick={(e) => e.stopPropagation()}>
          {openDrawerButtonVisible && (
            <IconButton onClick={() => props.onDrawerOpenClicked?.(props.notification)}>
              <ChromeReaderModeOutlined />
            </IconButton>
          )}
          <IconButton
            onClick={(e) => {
              setMenuAnchor(e.currentTarget);
              e.stopPropagation();
            }}
          >
            <MoreVertRounded />
          </IconButton>
        </Stack>
      )}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={(e: React.PointerEvent) => {
          setMenuAnchor(null);
          e.stopPropagation();
        }}
      >
        <MenuItem onClick={(e) => toggleReadState(e, props.notification)}>
          Mark as {props.notification.isRead ? "Unread" : "Read"}
        </MenuItem>
        <MenuItem onClick={(e) => handleDeleteClicked(e, props.notification)} sx={{ color: theme.palette.error.main }}>
          Delete
        </MenuItem>
      </Menu>
    </Stack>
  );
};
