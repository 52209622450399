import { Box, Card, CardContent, Grid, Portal, Skeleton, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { HeaderLayout, TextEditor, TextViewer, TextViewerTableOfContents } from "../../components";
import { useBreakpoints } from "../../hooks";
import { useEditWikiPage, useGetWikiPage } from "../../http";
import { setTitlePrefix } from "../../utilities";
import { WikiPageActions, WikiPageTitle } from "./components/";

interface UrlParams {
  slug: string;
}

interface Props {
  slug?: string;
}

const WikiPage = (props: Props) => {
  const { slug } = useParams<keyof UrlParams>() as UrlParams;
  const { data, isLoading } = useGetWikiPage(props.slug ?? slug);
  const { mutate } = useEditWikiPage();
  const [isEditing, setIsEditing] = useState(false);
  const [updatedContent, setUpdatedContent] = useState(data?.content ?? "");
  const { isMdUp } = useBreakpoints();
  const tableOfContentsRef = useRef<HTMLElement>();

  useEffect(() => {
    setTitlePrefix(data?.title);
  }, [data?.title]);

  function handleSaveClicked() {
    setIsEditing(false);

    mutate({
      title: data!.title,
      slug: "code-os-guide",
      content: updatedContent,
      editors: data!.editors,
    });
  }

  function handleCancelClicked() {
    setIsEditing(false);
  }

  return (
    <HeaderLayout
      title={<WikiPageTitle page={data} isLoading={isLoading} />}
      actions={
        <WikiPageActions
          page={data}
          isLoading={isLoading}
          isEditing={isEditing}
          onEditClicked={() => setIsEditing(true)}
          onCancelClicked={handleCancelClicked}
          onSaveClicked={handleSaveClicked}
        />
      }
    >
      {isLoading && (
        <Stack>
          <Skeleton width={400} />
          <Skeleton width={300} />
          <Skeleton width={500} />
          <Skeleton width={400} />
        </Stack>
      )}

      {!isLoading && data && (
        <>
          {isEditing ? (
            <Grid container>
              <Grid item xs={0} md={3} />
              <Grid item xs={12} md={6}>
                <Card>
                  <Box sx={{ m: 1 }}>
                    <TextEditor
                      initialValue={data.content}
                      onChange={(content) => setUpdatedContent(content)}
                      toolbarOptions={{
                        headings: true,
                      }}
                      ContentEditableProps={{
                        sx: {
                          maxHeight: "calc(100vh - 210px)",
                          overflow: "auto",
                        },
                      }}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1}>
              {isMdUp && (
                <Grid item xs={12} md={3}>
                  <Card sx={{ position: "sticky", top: 130, maxHeight: "calc(100vh - 150px)", overflow: "auto" }}>
                    <CardContent>
                      <Box ref={tableOfContentsRef} />
                    </CardContent>
                  </Card>
                </Grid>
              )}

              <Grid item xs={12} md={9} lg={6}>
                <Card>
                  <Box sx={{ m: 1 }}>
                    <TextViewer
                      value={data.content}
                      renderTableOfContents={(tableOfContentsArray) => {
                        if (isMdUp) {
                          return (
                            <Portal container={() => tableOfContentsRef.current!}>
                              <TextViewerTableOfContents tableOfContentsArray={tableOfContentsArray} />
                            </Portal>
                          );
                        } else {
                          return <></>;
                        }
                      }}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </HeaderLayout>
  );
};

export { WikiPage };
