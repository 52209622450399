import { Box, Card, CardContent, CardHeader, Divider, Skeleton, Stack } from "@mui/material";
import { useEffect, useRef } from "react";
import { TextViewer, Typography, isWhitespace } from "../../../../components";
import { useTeamPageLayoutContext } from "../../TeamPageLayout";

const TeamAbout = () => {
  const { isLoadingTeam, team } = useTeamPageLayoutContext();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current?.load();
  }, [team?.videoUrl]);

  return (
    <Card>
      <CardHeader title={<Typography variant="h6">About</Typography>} />
      <Divider />
      <CardContent>
        <Stack spacing={{ xs: 0.5, sm: 1 }}>
          {isLoadingTeam && (
            <>
              {[...Array(3)].map((_, i) => (
                <Box key={i}>
                  <Skeleton width={100} height={17} />
                  <Skeleton width={200} height={24} />
                </Box>
              ))}
            </>
          )}

          {!isLoadingTeam && (
            <>
              <Box>
                <Typography variant="overline">Purpose</Typography>
                <Typography>{team?.description}</Typography>
              </Box>

              {team?.hasVideo && (
                <Stack spacing={0.5}>
                  <Typography variant="overline">Team Video</Typography>
                  <video ref={videoRef} controls width="100%" style={{ borderRadius: 8 }}>
                    <source src={team.videoUrl} />
                  </video>
                </Stack>
              )}

              {team?.accountabilities && !isWhitespace(team.accountabilities) && (
                <Box>
                  <Typography variant="overline">Accountabilities</Typography>
                  <TextViewer value={team.accountabilities} />
                </Box>
              )}

              {team?.boundaries && !isWhitespace(team.boundaries) && (
                <Box>
                  <Typography variant="overline">Boundaries</Typography>
                  <TextViewer value={team.boundaries} />
                </Box>
              )}

              {team?.domains && !isWhitespace(team.domains) && (
                <Box>
                  <Typography variant="overline">Domains</Typography>
                  <TextViewer value={team.domains} />
                </Box>
              )}

              {team?.communicationChannels && !isWhitespace(team.communicationChannels) && (
                <Box>
                  <Typography variant="overline">Communication Channels</Typography>
                  <TextViewer value={team.communicationChannels} />
                </Box>
              )}

              {team?.otherInformation && !isWhitespace(team.otherInformation) && (
                <Box>
                  <Typography variant="overline">Other Information</Typography>
                  <TextViewer value={team.otherInformation} />
                </Box>
              )}
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export { TeamAbout };
