import { GridApiCommunity } from "@mui/x-data-grid-pro/internals";
import React, { useEffect, useState } from "react";
import { TeamPicker } from "..";

interface IProps {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}

const TeamFilterButton = (props: IProps) => {
  const [selectedTeamGuids, setSelectedTeamGuids] = useState<string[]>([]);

  // Clear selected nodes when filters are reset
  useEffect(() => {
    return props.apiRef.current.subscribeEvent("filterModelChange", (model) => {
      if (model.items.length === 0) {
        setSelectedTeamGuids([]);
      }
    });
  }, [props.apiRef]);

  // Apply a filter when a team is selected
  function handleTeamSelected(_: string, selectedTeamGuids: string[]) {
    setSelectedTeamGuids(selectedTeamGuids);

    props.apiRef.current.upsertFilterItem({
      id: "teamFilter",
      field: "teamId",
      operator: "isAnyOf",
      value: selectedTeamGuids,
    });
  }

  function handleClearClicked() {
    setSelectedTeamGuids([]);

    props.apiRef.current.upsertFilterItem({
      id: "teamFilter",
      field: "teamId",
      operator: "isAnyOf",
      value: [],
    });
  }

  return (
    <TeamPicker
      isMeVisible={false}
      selectedTeamGuids={selectedTeamGuids}
      onMeSelected={() => null}
      onTeamSelected={handleTeamSelected}
      onClearClicked={handleClearClicked}
    />
  );
};

export { TeamFilterButton };
