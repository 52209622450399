import { QueryClient, QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetNotificationCountDto, InfiniteNotificationsQueryData } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useMarkAllNotificationsAsReadRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function markAllNotificationsAsReadRequest() {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/notifications/mark-all-read`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { markAllNotificationsAsReadRequest };
};

const useMarkAllNotificationsAsRead = (userId: string) => {
  const { markAllNotificationsAsReadRequest } = useMarkAllNotificationsAsReadRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => markAllNotificationsAsReadRequest(),
    onMutate: async () => {
      const cachedNotificationsData = optimisticallyUpdateNotificationsData(
        queryClient,
        queryKeys.users.getNotifications(userId)
      );

      const cachedNotificationCountData = optimisticallyUpdateNotificationCountData(
        queryClient,
        queryKeys.users.getNotificationCount(userId)
      );

      return { cachedNotificationsData, cachedNotificationCountData };
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(queryKeys.users.getNotifications(userId), context?.cachedNotificationsData);
      queryClient.setQueryData(queryKeys.users.getNotificationCount(userId), context?.cachedNotificationCountData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.users.getNotifications(userId));
      queryClient.invalidateQueries(queryKeys.users.getNotificationCount(userId));
    },
  });
};

async function optimisticallyUpdateNotificationsData(queryClient: QueryClient, queryKey: QueryKey) {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<InfiniteNotificationsQueryData>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: InfiniteNotificationsQueryData = {
      ...cachedData,
      pages: cachedData.pages.map((page) => ({
        ...page,
        notifications: page.notifications.map((notification) => {
          return {
            ...notification,
            isRead: true,
          };
        }),
      })),
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);
    return cachedData;
  }
}

async function optimisticallyUpdateNotificationCountData(queryClient: QueryClient, queryKey: QueryKey) {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<GetNotificationCountDto>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: GetNotificationCountDto = {
      ...cachedData,
      unreadCount: 0,
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useMarkAllNotificationsAsRead };
