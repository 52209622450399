import React from "react";

interface ISignalRNotificationsContext {
  onShouldRefetchNotifications: (handler: (userGuid: string) => void) => void;
  offShouldRefetchNotifications: (handler: (userGuid: string) => void) => void;
}

const SignalRNotificationsContext = React.createContext<ISignalRNotificationsContext>({
  onShouldRefetchNotifications: () => null,
  offShouldRefetchNotifications: () => null,
});

export { SignalRNotificationsContext };
