import { Typography } from "@mui/material";
import { useRef } from "react";
import { ToolbarLayout } from "../../components";
import { useSettings } from "../../hooks";
import { setTitlePrefix } from "../../utilities";
import { HomeToolbar, OrganizationNetworkGraph, OrganizationNetworkGraphRef } from "./components";

const HomePage = () => {
  const organizationNetworkGraphRef = useRef<OrganizationNetworkGraphRef>(null);
  const { homeSettings } = useSettings();

  setTitlePrefix();

  return (
    <ToolbarLayout
      title={<Typography variant="h3">Home</Typography>}
      toolbar={<HomeToolbar organizationNetworkGraphRef={organizationNetworkGraphRef} />}
      contentContainerProps={{
        sx: { p: 0 },
      }}
    >
      <OrganizationNetworkGraph ref={organizationNetworkGraphRef} selectedTeamGuids={homeSettings.selectedTeamGuids} />
    </ToolbarLayout>
  );
};

export { HomePage };
