import { useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetNotificationCountDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetNotificationCountRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getNotificationCountRequest(): Promise<GetNotificationCountDto> {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/notifications/count`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getNotificationCountRequest };
};

const useGetNotificationCount = (userId: string) => {
  const { getNotificationCountRequest } = useGetNotificationCountRequest();

  return useQuery({
    queryKey: queryKeys.users.getNotificationCount(userId),
    queryFn: getNotificationCountRequest,
  });
};

export { useGetNotificationCount };
