import { QueryClient, QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, InfiniteNotificationsQueryData } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  notificationGuid: string;
};

const useDeleteNotificationRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteNotificationRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/notifications/${variables.notificationGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "delete",
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteNotificationRequest };
};

const useDeleteNotification = (userId: string) => {
  const { deleteNotificationRequest } = useDeleteNotificationRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteNotificationRequest,
    onMutate: async (variables) => {
      const cachedNotificationsData = optimisticallyUpdateNotificationsData(
        queryClient,
        queryKeys.users.getNotifications(userId),
        variables
      );

      return { cachedNotificationsData };
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(queryKeys.users.getNotifications(userId), context?.cachedNotificationsData);
    },
    onSettled: (_data, _error) => {
      queryClient.invalidateQueries(queryKeys.users.getNotifications(userId));
      queryClient.invalidateQueries(queryKeys.users.getNotificationCount(userId));
    },
  });
};

async function optimisticallyUpdateNotificationsData(
  queryClient: QueryClient,
  queryKey: QueryKey,
  variables: Variables
) {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<InfiniteNotificationsQueryData>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: InfiniteNotificationsQueryData = {
      ...cachedData,
      pages: cachedData.pages.map((page) => ({
        ...page,
        notifications: page.notifications.filter((notification) => notification.guid !== variables.notificationGuid),
      })),
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useDeleteNotification };
