import { Tonality } from "@mui/icons-material";
import { Divider, Skeleton, Stack } from "@mui/material";
import { ActionItemPropertyType, DEFAULT_ACTION_ITEM_STATUS, SupportingItemType } from "../../constants";
import { GetActionItemDto } from "../../http";
import { ITeamSummary } from "../../models";
import { ITeamSection } from "../EditActionItemSectionModal";
import {
  ActionItemDueDateProperty,
  ActionItemOwnershipProperty,
  ActionItemSupportingItemsProperty,
  ActionItemTeamProperty,
} from ".";
import { ItemProperty, NoteProperty, StatusPicker } from "..";

interface IProps {
  actionItem: GetActionItemDto | undefined;
  planGuid?: string;
  propertyVisibility?: {
    [key in ActionItemPropertyType]?: boolean;
  };
  isLoading?: boolean;
  onTeamChanged?: (selectedTeam: ITeamSummary | null, selectedSection: ITeamSection | null) => void;
}

const ActionItemProperties = (props: IProps) => {
  return (
    <>
      {props.isLoading && (
        <Stack
          spacing={0.5}
          sx={{ px: { xs: 0.5, sm: 1 }, py: 0.5 }}
          divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
        >
          <Skeleton width={"80%"} />
          <Skeleton width={"80%"} />
          <Skeleton width={"80%"} />
        </Stack>
      )}

      {!props.isLoading && props.actionItem && (
        <Stack
          spacing={0.5}
          sx={{ px: { xs: 0.5, sm: 1 }, py: 0.5 }}
          divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
        >
          <ActionItemTeamProperty actionItem={props.actionItem} onTeamChanged={props.onTeamChanged} />

          {(!props.propertyVisibility || props.propertyVisibility?.status) && (
            <ItemProperty labelIcon={<Tonality />} label="Status" rowLayoutOnSmallScreens>
              <StatusPicker
                actionItemGuid={props.actionItem.guid}
                status={props.actionItem.currentStatus ?? DEFAULT_ACTION_ITEM_STATUS}
                planGuid={props.planGuid}
                teamSlug={props.actionItem.team?.teamSlug}
                variant="select"
                disabled={!props.actionItem.userCanEdit}
              />
            </ItemProperty>
          )}

          {(!props.propertyVisibility || props.propertyVisibility?.ownership) && (
            <ActionItemOwnershipProperty actionItem={props.actionItem} planGuid={props.planGuid} />
          )}

          {(!props.propertyVisibility || props.propertyVisibility?.notes) && (
            <NoteProperty
              itemGuid={props.actionItem.guid}
              itemType={SupportingItemType.ActionItem}
              note={props.actionItem.note}
              userCanEdit={props.actionItem.userCanEdit}
            />
          )}

          {(!props.propertyVisibility || props.propertyVisibility.dueDate) && (
            <ActionItemDueDateProperty actionItem={props.actionItem} />
          )}

          {(!props.propertyVisibility || props.propertyVisibility?.supportingItems) && (
            <ActionItemSupportingItemsProperty actionItem={props.actionItem} />
          )}
        </Stack>
      )}
    </>
  );
};

export { ActionItemProperties };
