import { useContext } from "react";
import { SignalRContext } from "../SignalRContext";
import { SignalRClientMethod } from "../constants";
import { SignalRNotificationsContext } from "./SignalRNotificationsContext";

interface IProps {
  children?: React.ReactNode;
}

const SignalRNotificationsProvider = (props: IProps) => {
  const context = useContext(SignalRContext);

  return (
    <SignalRNotificationsContext.Provider
      value={{
        onShouldRefetchNotifications: (handler) =>
          context.registerHandler(SignalRClientMethod.ShouldRefetchNotifications, handler),
        offShouldRefetchNotifications: (handler) =>
          context.unregisterHandler(SignalRClientMethod.ShouldRefetchNotifications, handler),
      }}
    >
      {props.children}
    </SignalRNotificationsContext.Provider>
  );
};

export { SignalRNotificationsProvider };
