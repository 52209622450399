import { NotificationsNoneOutlined } from "@mui/icons-material";
import { Badge, Box, Button, Divider, IconButton, Popover, Stack, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useBreakpoints, useSession, useSettings, useSignalR } from "../../hooks";
import { useNavigate } from "react-router";
import { Links } from "../../constants";
import { CommentSkeleton, Notification, SignalRGroup } from "../../components";
import { queryKeys, useGetNotificationCount, useGetNotifications, useMarkAllNotificationsAsRead } from "../../http";
import { useQueryClient } from "@tanstack/react-query";

const ListItemText = styled(Typography)<{ $isExpanded: boolean }>`
  opacity: ${({ $isExpanded }) => ($isExpanded ? 1 : 0)};
  margin-left: ${({ $isExpanded }) => ($isExpanded ? "5px" : 0)};
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.875rem;
  transition: ${({ theme }) => theme.transitions.create(["opacity", "margin"])};
  text-transform: uppercase;
`;

const ScrollStack = styled(Stack)`
  overscroll-behavior: contain;
  min-height: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 50px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
`;

const SideMenuNotificationButton = () => {
  const { isSessionLoaded, userId } = useSession();
  const [notificationMenuAnchor, setNotificationMenuAnchor] = useState<null | HTMLElement>(null);
  const { isMdUp } = useBreakpoints();
  const { sideMenuSettings } = useSettings();
  const { data: notificationCount } = useGetNotificationCount(userId);
  const { isLoading: isLoadingNotifications, data: notifications } = useGetNotifications(
    userId,
    Boolean(notificationMenuAnchor)
  );
  const { mutate: markAllNotificationsAsRead } = useMarkAllNotificationsAsRead(userId);
  const navigate = useNavigate();
  const signalR = useSignalR();
  const queryClient = useQueryClient();

  useEffect(() => {
    signalR.joinGroup(SignalRGroup.Notifications(userId));
    return () => signalR.leaveGroup(SignalRGroup.Notifications(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    const handleShouldRefetchNotifications = () => {
      queryClient.invalidateQueries(queryKeys.users.getNotifications(userId));
      queryClient.invalidateQueries(queryKeys.users.getNotificationCount(userId));
    };

    signalR.onShouldRefetchNotifications(handleShouldRefetchNotifications);
    return () => signalR.offShouldRefetchNotifications(handleShouldRefetchNotifications);
  }, [queryClient, signalR, userId]);

  return (
    <>
      <Stack
        component="label"
        direction="row"
        spacing={0.5}
        sx={{ alignItems: "center", cursor: "pointer", width: "100%" }}
      >
        <IconButton
          onClick={(event) =>
            isMdUp ? setNotificationMenuAnchor(event.currentTarget) : navigate(Links.UserNotifications())
          }
          disabled={!isSessionLoaded}
        >
          <Badge badgeContent={notificationCount?.unreadCount} max={99} color="error">
            <NotificationsNoneOutlined fontSize="large" />
          </Badge>
        </IconButton>

        <ListItemText $isExpanded={sideMenuSettings.isExpanded}>Notifications</ListItemText>
      </Stack>
      <Popover
        anchorEl={notificationMenuAnchor}
        open={Boolean(notificationMenuAnchor)}
        onClose={() => setNotificationMenuAnchor(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack sx={{ width: "20vw", minWidth: "400px" }}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ mx: 1, my: 0.5, justifyContent: "space-between", alignItems: "baseline" }}
          >
            <Typography variant="h6">Notifications</Typography>
            <Button
              variant="tertiary"
              onClick={() => {
                markAllNotificationsAsRead();
              }}
            >
              Mark All Read
            </Button>
          </Stack>
          <Divider />

          <Stack>
            {isLoadingNotifications || notifications == null ? (
              <Stack sx={{ width: "100%" }} divider={<Divider variant="dashed" sx={{ mx: 1 }} />}>
                {[...Array(3)].map((_, i) => (
                  <CommentSkeleton sx={{ p: 1 }} width={200} key={i} />
                ))}
              </Stack>
            ) : notifications.pages[0].notifications.length > 0 ? (
              <ScrollStack
                sx={{ maxHeight: "40vh", overflowY: "auto" }}
                divider={<Divider variant="dashed" sx={{ mx: 1 }} />}
              >
                {notifications!.pages.flatMap((group) =>
                  group.notifications.map((notification) => (
                    <Notification notification={notification} key={notification.guid} />
                  ))
                )}
              </ScrollStack>
            ) : (
              <Typography variant="caption" textTransform="uppercase" alignSelf="center">
                You have no notifications!
              </Typography>
            )}
            <Divider />
            <Box sx={{ px: 1, py: 0.5 }}>
              <Button
                variant="tertiary"
                sx={{ alignSelf: "start" }}
                onClick={() => {
                  setNotificationMenuAnchor(null);
                  navigate(Links.UserNotifications());
                }}
              >
                View All Notifications
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export { SideMenuNotificationButton };
