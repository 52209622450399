import { TagType } from "../../constants";

const queryKeys = {
  comments: {
    getComments: (threadGuid: string | undefined) => [{ key: "comments", threadGuid }] as const,
  },
  issues: {
    getIssueDetail: (guid: string) => [{ key: "issueDetail", guid }] as const,
    getIssueFeed: (guid: string | undefined) => [{ key: "issueFeed", guid }] as const,
    getIssues: (teamSlug: string | undefined, userId: string | undefined) =>
      [{ key: "issues", teamSlug, userId }] as const,
  },
  organization: {
    getOrganizationNetworkGraph: () => [{ key: "organizationNetworkGraph" }] as const,
  },
  plans: {
    getPlans: () => [{ key: "plans" }] as const,
    getPlanVersions: (slug: string) => [{ key: "planVersions", slug }] as const,
    getPlanVersion: (slug: string | undefined, planVersionId: string | undefined, showChanges: boolean) =>
      [{ key: "planVersion", slug, planVersionId, showChanges }] as const,
    getPlan: (guid: string, date?: string | null, offsetInMinutes?: number | null) =>
      [{ key: "plan", guid, date, offsetInMinutes }] as const,
    getPlanHistory: (guid: string, offsetInMinutes: number) => [{ key: "planHistory", guid, offsetInMinutes }] as const,
    getKeyInitiativeDetail: (slug: string, guid: string) => [{ key: "keyInitiativeDetail", slug, guid }] as const,
    getConnectionPoints: (slug: string | undefined) => [{ key: "planConnectionPoints", slug }] as const,
    getStructure: (guid: string | undefined) => [{ key: "planStructure", guid }] as const,
  },
  actionItems: {
    getActionItemSummary: (guid: string | undefined) => [{ key: "actionItemSummary", guid }] as const,
    getActionItemDetail: (guid: string | undefined) => [{ key: "actionItemDetail", guid }] as const,
    getActionItemFeed: (guid: string | undefined) => [{ key: "actionItemFeed", guid }] as const,
  },
  meetings: {
    getOneOnOneMeetingsForUser: (userId?: string) => [{ key: "oneOnOneMeetingsForUser", userId }] as const,
    getOneOnOneMeetingsForAllUsers: () => [{ key: "oneOnOneMeetingsForUser" }] as const,
    getOneOnOneMeetingDetail: (meetingGuid: string) => [{ key: "oneOnOneMeetingDetail", meetingGuid }] as const,
    getMeetingParticipants: (meetingGuid: string) => [{ key: "meetingParticipants", meetingGuid }] as const,
    getMeetingNotes: (meetingGuid: string) => [{ key: "meetingNotes", meetingGuid }] as const,
  },
  metrics: {
    getMetricsForTeam: (slug: string) => [{ key: "teamMetrics", slug }] as const,
    getMetric: (guid: string) => [{ key: "metric", guid }] as const,
  },
  reports: {
    getOrganizationReport: (
      includeRoles?: boolean,
      includeRoleTags?: boolean,
      includeTeams?: boolean,
      includeParentTeams?: boolean
    ) => [{ key: "organizationReport", includeRoles, includeRoleTags, includeTeams, includeParentTeams }] as const,
  },
  resources: {
    getResourcesForTeam: (slug: string) => [{ key: "teamResources", slug }] as const,
  },
  roles: {
    getRole: (guid: string) => [{ key: "role", guid }] as const,
    getRolesForUser: (userId?: string) => [{ key: "userRoles", userId }] as const,
    getOpenRoles: () => [{ key: "openRoles" }] as const,
  },
  search: {
    getSearchResults: (query: string | null, filter: string | null) => [{ key: "search", query, filter }] as const,
  },
  tags: {
    getTags: (type: TagType) => [{ key: "tags", type }] as const,
  },
  teams: {
    getTeams: (userId?: string) => [{ key: "teams", userId }] as const,
    getTeamsTree: () => [{ key: "teamsTree" }] as const,
    getTeam: (slug?: string, userId?: string) => {
      let queryKey: Record<string, string> = {
        key: "team",
      };

      if (slug !== undefined) {
        queryKey.slug = slug;
      }

      if (userId !== undefined) {
        queryKey.userId = userId;
      }

      return [queryKey] as const;
    },
    getConnectedTeams: (slug: string) => [{ key: "connectedTeams", slug }] as const,
    getTeamNetworkGraph: (slug: string | undefined) => [{ key: "teamNetworkGraph", slug }] as const,
    getTeamNetworkList: (slug: string | undefined) => [{ key: "teamNetworkList", slug }] as const,
    getTeamActionItems: (slug: string | undefined) => [{ key: "teamActionItems", slug }] as const,
  },
  surveys: {
    getSurveys: (userId?: string) => [{ key: "surveys", userId }] as const,
    getSurvey: (guid: string | undefined) => [{ key: "survey", guid }] as const,
    getSurveyInstances: (surveyGuid: string) => [{ key: "surveyInstances", surveyGuid }] as const,
    getSurveyInstance: (guid: string | undefined | null) => [{ key: "surveyInstance", guid }] as const,
    getSurveyQuestions: (guid: string) => [{ key: "surveyQuestions", guid }] as const,
    getSurveyQuestion: (guid: string | undefined) => [{ key: "surveyQuestion", guid }] as const,
    getSurveyAnswersReport: (surveyGuid: string, surveyInstanceGuid?: string) =>
      [{ key: "surveyResponses", surveyGuid, surveyInstanceGuid }] as const,
    getSurveyCoherenceReport: (
      selectedSurveyInstanceGuid: string | undefined,
      comparisonSurveyInstanceGuid: string | undefined,
      selectedTeamGuids: string[] | undefined,
      comparisonTeamGuids: string[] | undefined
    ) =>
      [
        {
          key: "surveyCoherenceReport",
          selectedSurveyInstanceGuid,
          comparisonSurveyInstanceGuid,
          selectedTeamGuids,
          comparisonTeamGuids,
        },
      ] as const,
    getCoherenceScoreOverTime: (selectedTeamGuids: string[] | undefined, comparisonTeamGuids: string[] | undefined) =>
      [
        {
          key: "coherenceScoreOverTime",
          selectedTeamGuids,
          comparisonTeamGuids,
        },
      ] as const,
    getSurveyInstanceResponse: (instanceGuid: string | undefined) =>
      [{ key: "surveyInstanceResponse", instanceGuid }] as const,
  },
  users: {
    getUser: (userId?: string) => [{ key: "users", userId }] as const,
    getUsers: () => [{ key: "users" }] as const,
    getNotificationPreferences: (userId?: string) => [{ key: "notificationPreferences", userId }] as const,
    getNotifications: (userId?: string) => [{ key: "notifications", userId }] as const,
    getNotificationCount: (userId?: string) => [{ key: "notificationCount", userId }] as const,
    getSession: (azureAdUserId: string | undefined) => [{ key: "session", azureAdUserId }] as const,
  },
  wiki: {
    getWikiPage: (slug: string) => [{ key: "wikiPage", slug }] as const,
  },
};

export { queryKeys };
