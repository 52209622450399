import { OpenInBrowserRounded } from "@mui/icons-material";
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { ActionItemDrawerContent, CommentSkeleton, Notification, useDrawer } from "../../components";
import { Links } from "../../constants";
import { useSession } from "../../hooks";
import { NotificationDto, useGetNotifications } from "../../http";

const NotificationPageContent = () => {
  const navigate = useNavigate();
  const { userId } = useSession();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useGetNotifications(userId);

  const { showDrawer } = useDrawer();

  function handleOpenActionItemDrawerClicked(notification: NotificationDto) {
    showDrawer({
      title: (
        <Button
          variant="tertiary"
          endIcon={<OpenInBrowserRounded />}
          onClick={() => navigate(Links.ActionItemDetail(notification.subjectId))}
        >
          Open Page
        </Button>
      ),
      content: <ActionItemDrawerContent actionItemGuid={notification.subjectId} />,
    });
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Card>
          <Stack spacing={0.5} alignItems="flex-start">
            {isLoading || data == null ? (
              <Stack sx={{ width: "100%" }} divider={<Divider variant="dashed" sx={{ mx: 1 }} />}>
                {[...Array(3)].map((_, i) => (
                  <CommentSkeleton sx={{ p: 1 }} width={200} key={i} />
                ))}
              </Stack>
            ) : data.pages[0].notifications.length > 0 ? (
              <>
                <Stack sx={{ width: "100%" }} divider={<Divider variant="dashed" sx={{ mx: 1 }} />}>
                  {data!.pages.flatMap((group) =>
                    group.notifications.map((notification) => (
                      <Notification
                        notification={notification}
                        key={notification.guid}
                        isPageView
                        onDrawerOpenClicked={handleOpenActionItemDrawerClicked}
                      />
                    ))
                  )}
                </Stack>
                <Box sx={{ px: 0.5, pb: 0.5 }}>
                  <Button
                    variant="tertiary"
                    disabled={isFetchingNextPage || !hasNextPage}
                    endIcon={isFetchingNextPage && <CircularProgress size={20} sx={{ color: "grey.400" }} />}
                    onClick={() => fetchNextPage()}
                  >
                    {!hasNextPage ? "Nothing more to load" : isFetchingNextPage ? "Loading" : "Load more"}
                  </Button>
                </Box>
              </>
            ) : (
              <Typography variant="caption" textTransform="uppercase" alignSelf="center">
                You have no notifications!
              </Typography>
            )}
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};

export { NotificationPageContent };
