import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { SignalRGroup, ToolbarLayout } from "../../components";
import { queryKeys, useMarkAllNotificationsAsRead } from "../../http";
import { setTitlePrefix } from "../../utilities";
import { SettingsRounded } from "@mui/icons-material";
import { Links } from "../../constants";
import { useBreakpoints, useSession, useSignalR } from "../../hooks";
import { NotificationPageContent } from "./NotificationPageContent";
import { useQueryClient } from "@tanstack/react-query";

const NotificationsPage = () => {
  const navigate = useNavigate();
  const { userId } = useSession();
  const { isMdUp } = useBreakpoints();
  const { mutate: markAllNotificationsAsRead } = useMarkAllNotificationsAsRead(userId);
  const signalR = useSignalR();
  const queryClient = useQueryClient();

  useEffect(() => {
    setTitlePrefix("Notifications");
  }, []);

  useEffect(() => {
    signalR.joinGroup(SignalRGroup.Notifications(userId));
    return () => signalR.leaveGroup(SignalRGroup.Notifications(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    const handleShouldRefetchNotifications = () => {
      queryClient.invalidateQueries(queryKeys.users.getNotifications(userId));
    };

    signalR.onShouldRefetchNotifications(handleShouldRefetchNotifications);
    return () => signalR.offShouldRefetchNotifications(handleShouldRefetchNotifications);
  }, [queryClient, signalR, userId]);

  return (
    <ToolbarLayout
      actions={
        userId &&
        (isMdUp ? (
          <Button
            startIcon={<SettingsRounded />}
            variant="outlined"
            onClick={() => {
              navigate(Links.UserSettings());
            }}
          >
            Settings
          </Button>
        ) : (
          <IconButton
            color="primary"
            onClick={() => () => {
              navigate(Links.UserSettings());
            }}
          >
            <SettingsRounded />
          </IconButton>
        ))
      }
      title={<Typography variant="h3">Notifications</Typography>}
      toolbar={
        <Stack sx={{ width: "100%" }}>
          <Button
            variant="tertiary"
            onClick={() => {
              markAllNotificationsAsRead();
            }}
            sx={{ alignSelf: "end" }}
          >
            Mark All Read
          </Button>
        </Stack>
      }
    >
      <NotificationPageContent />
    </ToolbarLayout>
  );
};

export { NotificationsPage };
