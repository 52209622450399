import { CropFreeRounded } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { TeamPicker } from "../../../components";
import { useSettings } from "../../../hooks";
import { OrganizationNetworkGraphRef } from "./OrganizationNetworkGraph";

interface IProps {
  organizationNetworkGraphRef: React.RefObject<OrganizationNetworkGraphRef>;
}

const HomeToolbar = (props: IProps) => {
  const { homeSettings, updateHomeSettings } = useSettings();

  function handleVisualizationModeChanged() {
    updateHomeSettings({
      ...homeSettings,
      showPeople: !homeSettings.showPeople,
    });
  }

  function handleTeamSelected(_: string, selectedTeamGuids: string[]) {
    updateHomeSettings({
      ...homeSettings,
      selectedTeamGuids: selectedTeamGuids,
    });
  }

  function handleTeamsCleared() {
    updateHomeSettings({
      ...homeSettings,
      selectedTeamGuids: [],
    });
  }

  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Stack direction="row" spacing={0.5}>
        <TeamPicker
          isMeVisible={false}
          selectedTeamGuids={homeSettings.selectedTeamGuids}
          onTeamSelected={handleTeamSelected}
          onClearClicked={handleTeamsCleared}
        />
        <Button variant="tertiary" onClick={handleVisualizationModeChanged}>
          {homeSettings.showPeople ? "Hide People" : "Show People"}
        </Button>
      </Stack>
      <Button
        variant="tertiary"
        startIcon={<CropFreeRounded />}
        onClick={() => props.organizationNetworkGraphRef.current?.zoomToFit()}
      >
        Zoom To Fit
      </Button>
    </Stack>
  );
};

export { HomeToolbar };
