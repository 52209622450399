import { Box, Button, Chip, Stack } from "@mui/material";
import { useState } from "react";
import { NodeObject } from "react-force-graph-2d";
import { AvatarGroup, isWhitespace, TextViewer, Typography } from "../../../components";
import { Links } from "../../../constants";
import { useSession } from "../../../hooks/use-session";
import { useGetTeam } from "../../../http/hooks/teams/useGetTeam";
import { ITeamGraphNode } from "../../../models";

interface NetworkGraphTeamPreviewProps {
  node?: NodeObject<ITeamGraphNode>;
}

export const NetworkGraphTeamPreview = (props: NetworkGraphTeamPreviewProps) => {
  const { userId } = useSession();
  const { data: team } = useGetTeam(props.node?.slug, userId);
  const [showMore, setShowMore] = useState(false);

  return (
    <Stack spacing={1} sx={{ p: 1 }}>
      <Box>
        <Stack spacing={0.25}>
          {props.node?.parent && (
            <Chip
              label={props.node.parent.name}
              component="a"
              href={Links.Team(props.node.parent.slug)}
              clickable
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
              }}
              color="primary"
            />
          )}

          <Typography variant="h5">{props.node?.label}</Typography>
        </Stack>

        {!showMore && props.node?.description && <Typography variant="body1">{props.node.description}</Typography>}

        {showMore && (
          <Stack spacing={1} sx={{ mt: 0.5 }}>
            <Box>
              <Typography variant="overline">Purpose</Typography>
              {props.node?.description ? (
                <Typography>{props.node.description}</Typography>
              ) : (
                <Typography variant="placeholder">Nothing here yet.</Typography>
              )}
            </Box>

            {team?.accountabilities && !isWhitespace(team.accountabilities) && (
              <Box>
                <Typography variant="overline">Accountabilities</Typography>
                <TextViewer value={team.accountabilities}></TextViewer>
              </Box>
            )}

            {team?.boundaries && !isWhitespace(team.boundaries) && (
              <Box>
                <Typography variant="overline">Boundaries</Typography>
                <TextViewer value={team.boundaries} />
              </Box>
            )}

            {team?.domains && !isWhitespace(team.domains) && (
              <Box>
                <Typography variant="overline">Domains</Typography>
                <TextViewer value={team.domains} />
              </Box>
            )}

            {team?.communicationChannels && !isWhitespace(team.communicationChannels) && (
              <Box>
                <Typography variant="overline">Communication Channels</Typography>
                <TextViewer value={team.communicationChannels} />
              </Box>
            )}

            {team?.otherInformation && !isWhitespace(team.otherInformation) && (
              <Box>
                <Typography variant="overline">Other Information</Typography>
                <TextViewer value={team.otherInformation} />
              </Box>
            )}
          </Stack>
        )}

        <Button
          variant="tertiary"
          size="small"
          onClick={() => setShowMore((value) => !value)}
          sx={{ alignSelf: "flex-start", mt: 0.5 }}
        >
          {showMore ? "Show Less" : "Show More"}
        </Button>
      </Box>

      {props.node && props.node.members?.length > 0 && (
        <Box>
          <Typography variant="overline">People</Typography>
          <AvatarGroup users={props.node.members} max={4} />
        </Box>
      )}

      {team?.administrators && team.administrators.length > 0 && (
        <Box>
          <Typography variant="overline">Team Administrators</Typography>
          <AvatarGroup users={team?.administrators} max={4} />
        </Box>
      )}
    </Stack>
  );
};
