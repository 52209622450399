import { BarChartRounded, FilterListOffRounded } from "@mui/icons-material";
import { Button, Card, CardHeader, Divider, Grid, Stack, Typography } from "@mui/material";
import { createContext, useContext, useState } from "react";
import { IconLink, SurveyInstancePicker, TeamPicker, ToolbarLayout } from "../../../components";
import { configuration } from "../../../configuration";
import { Paths } from "../../../constants";
import { useGetCoherenceScoreOverTime, useGetSurveyCoherenceReport } from "../../../http";
import { setTitlePrefix } from "../../../utilities";
import { CoherenceGraph, CoherenceScores, LinearScaleResults, TextResponses } from "./components";

interface ICoherenceReportContext {
  selectedTeamLabel: string | undefined;
  selectedSurveyInstanceName: string | undefined;
  comparisonTeamLabel: string | undefined;
  comparisonSurveyInstanceName: string | undefined;
}

const CoherenceReportContext = createContext<ICoherenceReportContext | undefined>(undefined);

const CoherenceReportPage = () => {
  setTitlePrefix(`Coherence Report`);

  const [selectedSurveyInstanceName, setSelectedSurveyInstanceName] = useState<string | undefined>(undefined);
  const [selectedSurveyInstanceGuid, setSelectedSurveyInstanceGuid] = useState<string | undefined>(undefined);
  const [comparisonSurveyInstanceName, setComparisonSurveyInstanceName] = useState<string | undefined>(undefined);
  const [comparisonSurveyInstanceGuid, setComparisonSurveyInstanceGuid] = useState<string | undefined>(undefined);
  const [selectedTeamLabel, setSelectedTeamLabel] = useState<string | undefined>(undefined);
  const [comparisonTeamLabel, setComparisonTeamLabel] = useState<string | undefined>(undefined);
  const [selectedTeamGuids, setSelectedTeamGuids] = useState<string[] | undefined>(undefined);
  const [comparisonTeams, setComparisonTeamGuids] = useState<string[] | undefined>(undefined);

  const { data: report, isLoading } = useGetSurveyCoherenceReport(
    selectedSurveyInstanceGuid,
    comparisonSurveyInstanceGuid,
    selectedTeamGuids,
    comparisonTeams
  );

  const { data: coherenceGraph, isLoading: isLoadingGraph } = useGetCoherenceScoreOverTime(
    selectedTeamGuids,
    comparisonTeams
  );

  function handleSelectedSurveyInstanceChanged(name: string | undefined, guid: string | undefined) {
    setSelectedSurveyInstanceName(name);
    setSelectedSurveyInstanceGuid(guid);
  }

  function handleComparisonSurveyInstanceChanged(name: string | undefined, guid: string | undefined) {
    setComparisonSurveyInstanceName(name);
    setComparisonSurveyInstanceGuid(guid);
  }

  function handleMeSelected() {
    setSelectedTeamLabel(undefined);
    setSelectedTeamGuids(undefined);
  }

  function handleTeamSelected(aggregateTeamName: string, selectedTeamGuids: string[]) {
    if (selectedTeamGuids.length === 0) {
      setSelectedTeamLabel(undefined);
      setSelectedTeamGuids(undefined);
    } else {
      setSelectedTeamLabel(aggregateTeamName);
      setSelectedTeamGuids(selectedTeamGuids);
    }
  }

  function handleClearTeamClicked() {
    setSelectedTeamLabel(undefined);
    setSelectedTeamGuids(undefined);
  }

  function handleComparisonTeamSelected(aggregateTeamName: string, selectedTeamGuids: string[]) {
    if (selectedTeamGuids.length === 0) {
      setComparisonTeamLabel(undefined);
      setComparisonTeamGuids(undefined);
    } else {
      setComparisonTeamLabel(aggregateTeamName);
      setComparisonTeamGuids(selectedTeamGuids);
    }
  }

  function handleClearComparisonTeamClicked() {
    setComparisonTeamLabel(undefined);
    setComparisonTeamGuids(undefined);
  }

  function handleClearFiltersClicked() {
    setSelectedSurveyInstanceGuid(undefined);
    setComparisonSurveyInstanceGuid(undefined);
    handleClearTeamClicked();
    handleClearComparisonTeamClicked();
  }

  return (
    <CoherenceReportContext.Provider
      value={{
        selectedTeamLabel,
        selectedSurveyInstanceName,
        comparisonTeamLabel,
        comparisonSurveyInstanceName,
      }}
    >
      <ToolbarLayout
        title={
          <Stack>
            <IconLink href={Paths.Reports} startIcon={<BarChartRounded fontSize="small" />}>
              Reports
            </IconLink>
            <Typography variant="h3">Coherence Report</Typography>
            <Typography>A multidimensional employee experience feedback loop report.</Typography>
          </Stack>
        }
        toolbar={
          <Stack spacing={1} direction={{ xs: "column", lg: "row" }} justifyContent="space-between" width="100%">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 0.5, sm: 1 }}
              divider={<Divider flexItem orientation="vertical" />}
            >
              <Stack spacing={0.5} direction={{ xs: "column", md: "row" }}>
                <SurveyInstancePicker
                  surveyGuid={configuration.coherenceSurveyGuid}
                  surveyInstanceGuid={selectedSurveyInstanceGuid}
                  onChange={handleSelectedSurveyInstanceChanged}
                  hideActiveInstance
                />
                <TeamPicker
                  isMeVisible={true}
                  selectedTeamGuids={selectedTeamGuids ?? []}
                  onMeSelected={handleMeSelected}
                  onTeamSelected={handleTeamSelected}
                  onClearClicked={handleClearTeamClicked}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "inherit", md: "center" }}
                spacing={{ xs: 0.5, sm: 1 }}
              >
                <Typography>COMPARE TO</Typography>
                <Stack spacing={0.5} direction={{ xs: "column", md: "row" }}>
                  <SurveyInstancePicker
                    surveyGuid={configuration.coherenceSurveyGuid}
                    surveyInstanceGuid={comparisonSurveyInstanceGuid}
                    onChange={handleComparisonSurveyInstanceChanged}
                    hideActiveInstance
                  />
                  <TeamPicker
                    isMeVisible={false}
                    selectedTeamGuids={comparisonTeams ?? []}
                    onMeSelected={() => null}
                    onTeamSelected={handleComparisonTeamSelected}
                    onClearClicked={handleClearComparisonTeamClicked}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Button variant="tertiary" startIcon={<FilterListOffRounded />} onClick={handleClearFiltersClicked}>
                Clear Filters
              </Button>
            </Stack>
          </Stack>
        }
      >
        <Grid container spacing={{ xs: 0.5, sm: 1 }}>
          <Grid item xs={12}>
            <CoherenceScores isLoading={isLoading} report={report} />
          </Grid>
          <Grid item xs={12}>
            <CoherenceGraph isLoading={isLoadingGraph} data={coherenceGraph} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={<Typography variant="h4">Rating Responses</Typography>} />
              <Divider sx={{ color: "#c9c9c9" }} />
              <LinearScaleResults isLoading={isLoading} report={report} />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={<Typography variant="h4">Text Responses</Typography>} />
              <Divider sx={{ color: "#c9c9c9" }} />
              <TextResponses isLoading={isLoading} report={report} />
            </Card>
          </Grid>
        </Grid>
      </ToolbarLayout>
    </CoherenceReportContext.Provider>
  );
};

const useCoherenceReportContext = () => {
  const context = useContext(CoherenceReportContext);

  if (context === undefined) {
    throw new Error("CoherenceReportContext is not inititalized.");
  }

  return context;
};

export { CoherenceReportPage, useCoherenceReportContext };
