import { Box, styled, SxProps } from "@mui/material";
import { HeaderLayout } from "./HeaderLayout";

const Toolbar = styled(Box)`
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
`;

const ContentContainer = styled(Box)``;

interface IProps {
  title: React.ReactNode;
  actions?: React.ReactNode;
  toolbar: React.ReactNode;
  children: React.ReactNode;
  toolbarContainerProps?: IToolbarContainerProps;
  contentContainerProps?: IContentContainerProps;
}

interface IToolbarContainerProps {
  sx?: SxProps;
}

interface IContentContainerProps {
  sx?: SxProps;
}

const ToolbarLayout = (props: IProps) => {
  const { children, toolbar, toolbarContainerProps, contentContainerProps, ...headerLayoutProps } = props;

  return (
    <HeaderLayout
      {...headerLayoutProps}
      contentContainerProps={{
        sx: {
          p: 0,
        },
      }}
    >
      <Toolbar sx={{ px: { xs: 0.5, sm: 1 }, py: 0.5 }} {...toolbarContainerProps}>
        {toolbar}
      </Toolbar>
      <ContentContainer sx={{ p: { xs: 0.5, sm: 1 } }} {...contentContainerProps}>
        {children}
      </ContentContainer>
    </HeaderLayout>
  );
};

export { ToolbarLayout };
