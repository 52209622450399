import OpenInBrowserTwoToneIcon from "@mui/icons-material/OpenInBrowserTwoTone";
import { Box, Button, Stack, alpha, useTheme } from "@mui/material";
import * as d3 from "d3";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import ForceGraph2D, { ForceGraphMethods, GraphData, NodeObject } from "react-force-graph-2d";
import { useNavigate } from "react-router";
import { NetworkGraphTeamPreview, NetworkGraphTooltip, NetworkGraphUserPreview } from ".";
import { CodeSpinnerIcon } from "../../../assets/icons";
import { useDrawer } from "../../../components";
import { useSession, useSettings } from "../../../hooks";
import { useGetOrganizationNetworkGraph } from "../../../http";
import { INetworkGraphLink, INetworkGraphNode, ITeamGraphNode, IUser, NetworkGraphNodeType } from "../../../models";
import { NODE_RADIUS, calculateOpacity, drawNode } from "../../../utilities";
import { TeamFollowersButton } from "../../TeamPages/TeamPageLayout/components/TeamFollowersButton";

interface OrganizationNetworkGraphProps {
  selectedTeamGuids: string[];
}

interface OrganizationNetworkGraphRef {
  zoomToFit: () => void;
}

const OrganizationNetworkGraph = React.forwardRef<OrganizationNetworkGraphRef, OrganizationNetworkGraphProps>(
  (props, ref) => {
    const { showDrawer } = useDrawer();
    const { userId } = useSession();
    const { data, isLoading } = useGetOrganizationNetworkGraph();
    const { homeSettings } = useSettings();
    const [mutableData, setMutableData] = useState<GraphData<INetworkGraphNode, INetworkGraphLink>>({
      nodes: [],
      links: [],
    });
    const theme = useTheme();
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);
    const forceGraphRef = useRef<ForceGraphMethods<INetworkGraphNode, INetworkGraphLink>>();
    const tickRef = useRef<number>(0);
    const [hoveredGraphNode, setHoveredGraphNode] = useState<INetworkGraphNode>();
    const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const resizeTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);
    const [graphDimensions, setGraphDimensions] = useState<{ height?: number; width?: number }>({});
    const isZoomingRef = useRef(false);

    useImperativeHandle(
      ref,
      () => {
        return {
          zoomToFit: () => forceGraphRef.current?.zoomToFit(1000, 100),
        };
      },
      []
    );

    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    });

    function handleResize() {
      // Buffered to only resize every 500ms
      clearTimeout(resizeTimerRef.current);
      resizeTimerRef.current = setTimeout(() => {
        setGraphDimensions({
          height: containerRef.current?.clientHeight,
          width: containerRef.current?.clientWidth,
        });

        setTimeout(() => {
          forceGraphRef.current?.zoomToFit(1000, 100);
        }, 200);
      }, 500);
    }

    // Configure initial height and width
    useEffect(() => {
      setGraphDimensions({
        height: containerRef.current?.clientHeight,
        width: containerRef.current?.clientWidth,
      });
    }, []);

    // Configure d3 forces
    useEffect(() => {
      if (forceGraphRef.current) {
        forceGraphRef.current.d3Force("charge", d3.forceManyBody().strength(-2000).distanceMax(2000));
        forceGraphRef.current.d3Force("collide", d3.forceCollide(NODE_RADIUS));
        forceGraphRef.current.d3Force("link", d3.forceLink().distance(200).strength(2));
      }
    }, []);

    // Set up data structure used by react-force-graph
    useEffect(() => {
      if (data === undefined) {
        return;
      }

      const teamNodes = data.teamNodes
        .filter((x) => (props.selectedTeamGuids.length === 0 ? true : props.selectedTeamGuids.includes(x.id)))
        .map((x): NodeObject<INetworkGraphNode> => ({ ...x, fx: undefined, fy: undefined }));

      const teamLinks = data.teamLinks
        .filter((x) => {
          return props.selectedTeamGuids.length === 0
            ? true
            : props.selectedTeamGuids.includes(x.source) && props.selectedTeamGuids.includes(x.target);
        })
        .map((x) => ({ ...x }));

      const peopleLinks = homeSettings.showPeople
        ? data.peopleLinks
            .filter((x) => (props.selectedTeamGuids.length === 0 ? true : props.selectedTeamGuids.includes(x.source)))
            .map((x) => ({ ...x }))
        : [];

      const peopleNodes = homeSettings.showPeople
        ? data.peopleNodes
            .filter((x) => (props.selectedTeamGuids.length === 0 ? true : peopleLinks.some((y) => y.target === x.id)))
            .map((x): NodeObject<INetworkGraphNode> => ({ ...x, fx: undefined, fy: undefined }))
        : [];

      setMutableData({
        nodes: teamNodes.concat(peopleNodes),
        links: teamLinks.concat(peopleLinks),
      });

      setTimeout(() => {
        forceGraphRef.current?.zoomToFit(1000, 100);
      }, 200);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, homeSettings, props.selectedTeamGuids]);

    function handleNodeClicked(node: NodeObject<INetworkGraphNode>, event: MouseEvent) {
      if (event.ctrlKey) {
        window.open(node.url, "_blank");
      } else if (event.shiftKey) {
        window.open(node.url, "_new");
      } else {
        handleNodePreviewItemClicked(node);
      }
    }

    function getHoverNodePosition(node: NodeObject<INetworkGraphNode>): { x: number; y: number } | undefined {
      if (forceGraphRef.current && containerRef.current) {
        let screenCoord = forceGraphRef.current.graph2ScreenCoords(node.x!, node.y!);
        // Adjust coordinates based on the bounding box of the graph's container.
        let adjustedPosition = {
          x: screenCoord.x + containerRef.current.getBoundingClientRect().left,
          y: screenCoord.y + containerRef.current.getBoundingClientRect().top,
        };
        return adjustedPosition;
      }
    }

    function handleNodePreviewItemClicked(node: NodeObject<INetworkGraphNode>) {
      // Close any open hover preview
      setIsOpen(false);
      setHoveredGraphNode(undefined);

      showDrawer({
        title: () => {
          switch (node.type) {
            case NetworkGraphNodeType.Member:
              return (
                <Button
                  variant="tertiary"
                  startIcon={<OpenInBrowserTwoToneIcon />}
                  onClick={() => {
                    navigate(node?.url);
                  }}
                >
                  OPEN PERSON PAGE
                </Button>
              );
            case NetworkGraphNodeType.RootTeam:
            case NetworkGraphNodeType.ParentTeam:
            case NetworkGraphNodeType.ChildTeam:
              const teamNode = node as NodeObject<ITeamGraphNode>;

              return (
                <Stack direction={"row"} spacing={0.5}>
                  <Button
                    variant="tertiary"
                    startIcon={<OpenInBrowserTwoToneIcon />}
                    onClick={() => {
                      navigate(teamNode.url);
                    }}
                  >
                    OPEN TEAM PAGE
                  </Button>
                  {!teamNode.members.some((member: IUser) => member.userId === userId) && (
                    <TeamFollowersButton
                      teamSlug={teamNode.slug}
                      userId={userId}
                      buttonVariant="tertiary"
                    ></TeamFollowersButton>
                  )}
                </Stack>
              );
          }
        },
        content: () => {
          switch (node.type) {
            case NetworkGraphNodeType.Member:
              return <NetworkGraphUserPreview node={node} />;
            case NetworkGraphNodeType.RootTeam:
            case NetworkGraphNodeType.ParentTeam:
            case NetworkGraphNodeType.ChildTeam:
              const teamNode = node as NodeObject<ITeamGraphNode>;
              return <NetworkGraphTeamPreview node={teamNode} />;
          }
        },
      });
    }

    return (
      <>
        <NetworkGraphTooltip open={isOpen} position={tooltipPosition} networkNode={hoveredGraphNode} />
        <Box
          ref={containerRef}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            height: "calc(100vh - 132px)",
            width: "100%",
            overflow: "hidden",
          }}
        >
          {isLoading && <CodeSpinnerIcon sx={{ position: "absolute", zIndex: 3 }} />}

          <ForceGraph2D
            ref={forceGraphRef}
            graphData={mutableData}
            height={graphDimensions.height}
            width={graphDimensions.width}
            nodeCanvasObject={(node, canvas, scale) =>
              drawNode(
                node,
                canvas,
                scale,
                theme,
                true,
                node.type === NetworkGraphNodeType.RootTeam
                  ? NODE_RADIUS * 8
                  : node.type === NetworkGraphNodeType.Member && node.id === userId
                  ? NODE_RADIUS * 4
                  : 0,
                calculateOpacity(tickRef.current, node.depth)
              )
            }
            onNodeHover={(node) => {
              if (!isZoomingRef.current) {
                if (node) {
                  setIsOpen(true);
                  setHoveredGraphNode(node);
                  setTooltipPosition(getHoverNodePosition(node));
                } else {
                  setIsOpen(false);
                  setHoveredGraphNode(undefined);
                }
              }
            }}
            nodeVal={20}
            linkDirectionalArrowLength={5}
            linkDirectionalArrowRelPos={1}
            linkCurvature={0.25}
            linkLineDash={[5, 3]}
            linkColor={(link) => {
              const source = link.source as NodeObject<INetworkGraphNode>;
              const opacity = calculateOpacity(tickRef.current, source.depth) ?? 1;
              return alpha("#cacaca", opacity);
            }}
            linkWidth={2}
            onEngineTick={() => tickRef.current++}
            onNodeClick={handleNodeClicked}
            onZoom={() => (isZoomingRef.current = true)}
            onZoomEnd={() => (isZoomingRef.current = false)}
            warmupTicks={50}
            maxZoom={2}
            minZoom={0.1}
          />
        </Box>
      </>
    );
  }
);

export { OrganizationNetworkGraph };
export type { OrganizationNetworkGraphRef };
